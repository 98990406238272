import React from 'react';
import Head from '../../components/layout/Head';
import Layout from '../../components/layout/Layout';
import AuthWrapper from '../../components/layout/AuthWrapper';
import ViewEnquiry from '../../components/organisms/view-enquiry/view-enquiry';

const ViewEnquiryPage = () => {
  return (
    <AuthWrapper>
      <Head title="View Enquiry" />
      <Layout
        section="templates"
        breadcrumbs={[
          { link: '/', label: 'Home' },
          { link: '/list-enquiries', label: 'Enquiries' },
          { label: 'View Enquiry' },
        ]}
      >
        <ViewEnquiry />
      </Layout>
    </AuthWrapper>
  );
};

export default ViewEnquiryPage;
