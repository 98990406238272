import React, { useCallback, useEffect, useState } from 'react';
import { getQueryVar } from '../../../utils/query-vars';
import Stack from '../../atoms/stack/Stack';
import {
  ContentContainer,
  LightContentBox,
  WordWrapBox,
} from '../../layout/Layout.style';
import {
  FormFields,
  FormTitleHeader,
  ReviewFormContainer,
} from '../Form/Form.style';
import { IEnquiry } from '../../../context-providers/enquiries/enquiries-reducer';
import EnquiryUtil from '../../../utils/enquiry-util';
import FormText from '../Form/FormText';
import FormField from '../Form/FormField';
import ReferenceDataHelper from '../../../utils/reference-data-helper';
import { formatDateTime } from '../../../utils/dates';

const ViewEnquiry = () => {
  const [enquiry, setViewEnquiry] = useState<IEnquiry | null>(null);

  const enquiryId = getQueryVar('eid');

  if (!enquiryId) {
    throw new Error('Enquiry Id is required');
  }

  const loadEnquiry = useCallback(async () => {
    if (!enquiryId) {
      return;
    }
    const form = await EnquiryUtil.getById(enquiryId);
    await EnquiryUtil.setViewed(enquiryId);
    setViewEnquiry(form);
  }, [enquiryId]);

  useEffect(() => {
    loadEnquiry();
  }, [loadEnquiry]);

  const [lldds, setLldds] = useState<ILearnerLLDD[]>([]);
  const [llddsLoaded, setLlddsLoaded] = useState<boolean>(false);

  const [ethnicities, setEthnicities] = useState<ILearnerEthnicity[]>([]);
  const [ethnicitiesLoaded, setEthnicitiesLoaded] = useState<boolean>(false);

  const [sex, setSex] = useState<ILearnerSex[]>([]);
  const [sexLoaded, setSexLoaded] = useState<boolean>(false);

  const loadLLDDs = (): void => {
    if (!llddsLoaded) {
      ReferenceDataHelper.getLllds().then((response) => {
        setLldds(response);
        setLlddsLoaded(true);
      });
    }
  };

  const loadEthnicities = (): void => {
    if (!ethnicitiesLoaded) {
      ReferenceDataHelper.getEthnicities().then((response) => {
        setEthnicities(response);
        setEthnicitiesLoaded(true);
      });
    }
  };

  const loadSex = (): void => {
    if (!sexLoaded) {
      console.log('getting sex FieldsPrePop');
      ReferenceDataHelper.getSex().then((response) => {
        setSex(response);
        setSexLoaded(true);
      });
    }
  };

  useEffect(() => {
    loadSex();
  }, [sexLoaded]);

  useEffect(() => {
    loadEthnicities();
  }, [ethnicitiesLoaded]);

  useEffect(() => {
    loadLLDDs();
  }, [llddsLoaded]);

  if (enquiry === null) {
    return null;
  }

  return (
    <>
      <ReviewFormContainer>
        <ContentContainer small>
          <FormTitleHeader>
            <div>
              <h1>
                {enquiry.formTemplate.formName} - {enquiry.enquiryName}
              </h1>
              <p>Created on: {formatDateTime(enquiry.enquiryDate)}</p>
            </div>
          </FormTitleHeader>
          <LightContentBox>
            <Stack stackMultiplier={3}>
              {enquiry.formTemplate.formData.map((field, key) =>
                field.itemType === 'text' ? (
                  <WordWrapBox key={field.id}>
                    <FormText field={field} isVisible />
                  </WordWrapBox>
                ) : (
                  <WordWrapBox key={field.id}>
                    <FormFields hasMaxWidth={field.inputType !== 'DataTable'}>
                      <FormField
                        formId={enquiry.formTemplateId}
                        userType={'enquiry'}
                        index={key}
                        field={field}
                        sexOptions={sex}
                        llddOptions={lldds}
                        ethnicityOptions={ethnicities}
                        isEnquiry={true}
                        reviewing={true}
                      />
                    </FormFields>
                  </WordWrapBox>
                ),
              )}
            </Stack>
          </LightContentBox>
        </ContentContainer>
      </ReviewFormContainer>
    </>
  );
};

export default ViewEnquiry;
